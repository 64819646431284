<template>

  <header class="header">
    <div class="header-content">
        <a href="/" class="header-content__container-logo">
            <brand-header :image="urlImage" :altImage="altImagen"/>
        </a>
    </div>
  </header>
</template>

<script>
  import BrandHeader from '@/components/brand-header.vue';


  export default {
    name: "HeaderComponent",
    components: {
      BrandHeader
    },
    data() {
      return {
        urlImage: ('https://images.betimages.com/Betslip/images/vipgaming/logo.png'),
        altImage: 'Logo de VipGmain.mx',
      }
    },
  };
</script>
