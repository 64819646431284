<template>
  <footer class="footer">
    <div class="footer-content">
      <RestrictionText/>
      <div class="list-container">
        <ul class="footer-content__list">
          <li class="footer-content__list--item" v-for="(route, index) in routes" :key="index">
            <div style="color:white; cursor: pointer;" @click="handleRouteClick(route.route)">{{ route.name }}</div>
          </li>
        </ul>
      </div>
    </div>
    <ModalComponentRules ref="modal" :iframeUrl="iframeUrl" :modalType="modalType" :modalTitle="modalTitle" />
  </footer>
</template>

<script>
import RestrictionText from "@/components/restriction-text.vue";
import ModalComponentRules from '@/components/modal-rules.vue';

export default {
  name: "FooterComponent",
  components: {
    RestrictionText,
    ModalComponentRules
  },
  data() {
    return {
      iframeUrl: '',
      modalType: '', // Agregamos la propiedad modalType
      modalTitle: '' // Agregamos la propiedad modalTitle
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleRouteClick(route) {
      if (route === 'reglas-de-deportes') {
        this.openModal('https://media.betimages.com/media/rules/sports-rules-groupbetamerica.html', 'deportes', 'Reglas de Deportes');
      } 
      if (route === 'reglas-de-caballos') {
        this.openModal('https://media.betimages.com/media/rules/horse-rules-esp.html', 'caballos', 'Reglas de Caballos');
      } 
      if (route === 'reglas-de-casino') {
        this.openModal('https://media.betimages.com/media/rules/rules-casino-es-betman.html', 'casino', 'Reglas de Casino');
      }
      else {
        this.scrollToTop();
      }
    },
    openModal(url, type, title) {
      this.iframeUrl = url;
      this.modalType = type; // Establecemos el tipo de modal
      this.modalTitle = title; // Establecemos el título del modal
      this.$refs.modal.openModal();
    }
  },
  setup() {
    return {
      routes: [
        {
          name: 'Reglas de Deportes',
          route: 'reglas-de-deportes'
        },
        {
          name: 'Reglas de Caballos',
          route: 'reglas-de-caballos'
        },
        {
          name: 'Reglas de Casino',
          route: 'reglas-de-casino'
        },
      ]
    };
  }
};
</script>
