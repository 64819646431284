<template>
  <div class="restriction-container">
    <p class="custom-restriction-description">{{ restrictionDescription }}</p>
  </div>
</template>

<script>
export default {
  name: "RestrictionText",
  data() {
    return {
      restrictionDescription : "This website is not for US citizens or any person residing in the US",
    };
  }
};
</script>