<template>
  <div class="modal-backdrop" v-if="showModal">
    <div :class="['modal-content modal-content-rules', modalTypeClass]">
      <div class="modal-backdrop__header">
        <h3 class="modal-title">{{ modalTitle }}</h3> <!-- Mostrar el título dinámico aquí -->
        <button class="close-button" @click="closeModal">
          <span class="material-symbols-rounded">close</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="500px" style="border: none;"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponentRules',
  props: {
    iframeUrl: {
      type: String,
      required: false
    },
    modalType: {
      type: String,
      required: false
    },
    modalTitle: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    modalTypeClass() {
      return this.modalType === 'casino' ? 'modal-casino' : '';
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>