<template>
        <img class="brand-header__img" :src="image" alt="Logo de VipGmain.mx" />
</template>

<script>
    export default {
        name: "BrandComponent",
        props: {
            image: {
                type: String,
                required: true
            },
            altImage: {
                type: String,
                required: true
            },
        },
        setup() {

        },
    };
</script>
